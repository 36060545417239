import React, { useEffect, useMemo ,useState} from 'react';
import { Skeleton } from 'antd';
import CardRounded from '../CardRounded';
import { useGetDay_reserves } from '../../api/gym_classes';
import dayjs from 'dayjs';
import { Statistics } from '../reserves/Statistics';
import { ReserveList } from '../reserves/ReserveList';
import { useGlobalContext } from '../../context/GlobalState';
import { getCoachClass, updateCoachClassStatus } from '../../api/calendar';
import { message,notification } from 'antd';
import { openNotificationFields } from '../../services/utils/openNotificationFields';
import { formatError } from '../../services/utils/formatError';
import { useMutation} from '@tanstack/react-query';
import { t } from 'i18next';

const today = dayjs().format('YYYY-MM-DD');
export default function ListClassMembers({
  classes,
  setModalMemberOpen,
  class_selected,
  hour_selected,
  day_selected,
  useDelete_member_gym_class,
  usePatch_member_gym_class,
  reserves_trials,
  
}) {
  const hourSelectedFormatted = dayjs(hour_selected).format('HH:mm');
  const { state: user } = useGlobalContext();
  const [messageApi, messageContext] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();
const [toggle_wait_list, settoggle_wait_list] = useState(false)
  const [coachAttendance, setcoachAttendance] = useState(false)
const isCoach = user.user?.roles.some((r)=>r.id===3)
  const {
    isLoading,
    isError,
    data: reserves,
    error,
    isFetching
  } = useGetDay_reserves(
    day_selected ? day_selected.format('YYYY-MM-DD') : today
  );
  const init_loading = () => {
    messageApi.open({
      type: 'loading',
      content: 'Cargando...',
      duration: 0,
      // style: { backgroundColor: '#222222', color: 'white' },
    });
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ?  t('reserves.attendance_taken',`Se tomo asistencia correctamente`)
          :  t('reserves.attendance_error',`Ocurrió un error al tomar asistencia`),
     ...openNotificationFields(type, description)
    });
  };
  const useUpdateCoach = useMutation({
    mutationFn: (data) => updateCoachClassStatus(data.id,data.data),
    onSuccess: () => {
      openNotification('success');
     setcoachAttendance(false)
      messageApi.destroy();
    },
    onError: (error) => {
      formatError(error, openNotification)
      messageApi.destroy();
    },
  });
  const checkCoachClass = async ()=>{
    const result = await getCoachClass(class_selected,dayjs(hour_selected).utc(),user.user.coach_id)      
    if(result && result.length > 0){
      if(!result[0].has_attended){
       return setcoachAttendance(result[0].id)
      }
    }
  return setcoachAttendance(false)
  }
useEffect(() => {
  if(isCoach && user.user.coach_id && class_selected && hour_selected){
    checkCoachClass()
  }
}, [class_selected,hour_selected])
  useEffect(() => {
    settoggle_wait_list(false)
  }, [class_selected,hour_selected,day_selected])
  
  const countByCondition = (conditionFn) => {
    return filteredReserves.reduce(
      (acc, reserve) => (conditionFn(reserve) ? acc + 1 : acc),
      0
    );
  };
  const getFilteredReserves = (
    reserves,
    reserves_trials,
    class_selected,
    hour_selected,
    wait_list = false
  ) => {
    const trialReserves = reserves_trials?.filter(
      (reserve) =>
        reserve.gym_class_id === class_selected 
        &&
        dayjs(reserve.scheduled_at).format('HH:mm') ===
          dayjs(hour_selected).format('HH:mm') && 
          dayjs(reserve.scheduled_at).format('YYY-MM-DD') ===
          dayjs(day_selected).format('YYY-MM-DD')
          &&
          reserve.wait_list === wait_list
    );
    const allReserves = (reserves || [])
      .filter(
        (reserve) =>
          reserve.gym_class_id === class_selected &&
          dayjs(reserve.scheduled_at).format('HH:mm') ===
            dayjs(hour_selected).format('HH:mm') &&
            reserve.wait_list === wait_list
      )
      .concat(trialReserves)
      .sort((a, b) =>
        a.subscription.owner.first_name > b.subscription.owner.first_name
          ? 1
          : -1
      )

    return allReserves;
  };
  const filteredReserves = useMemo(
    () =>
      getFilteredReserves(
        reserves,
        reserves_trials,
        class_selected,
        hour_selected,false
      ),
    [reserves, reserves_trials, class_selected, hour_selected]
  );
  const max_attendances = useMemo(
    () =>
      classes?.find(
        (c) =>
          c.id === class_selected &&
          c.scheduled_at &&
          dayjs(c.scheduled_at).format('HH:mm') === hourSelectedFormatted
      )?.max_attendances,
    [classes, class_selected, hourSelectedFormatted]
  );
  const wait_list_members = useMemo(
    () =>
      getFilteredReserves(
        reserves,
        reserves_trials,
        class_selected,
        hour_selected,true
      ),
      [filteredReserves]
    );
    
  return (
    <>
    <CardRounded styles='max-h-[35rem] rounded-t-none'>
      {isLoading ? (
        <Skeleton paragraph={{ rows: 15 }} active />
      ) : (
        <div>
          {classes && class_selected && hour_selected && (
            <Statistics
              filteredReserves={filteredReserves}
              countByCondition={countByCondition}
              max_attendances={max_attendances}
              hour_selected={hour_selected}
              init_loading={init_loading}
              messageApi={messageApi}
              wait_list_members={wait_list_members}
              toggle_wait_list={toggle_wait_list}
              settoggle_wait_list={settoggle_wait_list}
            />
          )}
          { coachAttendance ? 
               <div className='h-full m-auto flex jic p-20'>
          <button
          onClick={async() => {
            init_loading()
           await useUpdateCoach.mutateAsync({
              id: coachAttendance,
              data: {
                has_attended: true,
              },
            });
          }}
          className='text-center m-auto underline text-primaryGreen'>
          {t('reserves.take_attendance','Realizar check-in de la clase')}
          </button>
        </div>
        : (
            <ReserveList
              setModalMemberOpen={setModalMemberOpen}
              filteredReserves={filteredReserves}
              class_selected={class_selected}
              hour_selected={hour_selected}
              toggle_wait_list={toggle_wait_list}
              wait_list_members={wait_list_members}
              useDelete_member_gym_class={useDelete_member_gym_class}
              usePatch_member_gym_class={usePatch_member_gym_class}
            />
          )}
        </div>
      )}

    </CardRounded>
    {contextHolder}
    {messageContext}
    </>
  );
}
