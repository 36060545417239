import React,{useState} from 'react'
import {
    Tooltip,
    Tag,
    Dropdown,
  } from 'antd';
  import { MdOutlineModeEditOutline } from 'react-icons/md';
import { formatExtraInfo } from '../../services/membersUtils';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import {  BsThreeDots } from 'react-icons/bs';
import {
  getLocationById,
  getLocationId,
} from '../../services/utils/getLocation';
 import dayjs from 'dayjs'
 import { t } from 'i18next';
import { useGlobalContext } from '../../context/GlobalState';

export default function LineDebitMember({debits,editProduct,editSplit,openNotification,showPromiseConfirm,setopenNotification1,handleLink,handleOpenDebit}) {
    const [showSplitId, setshowSplitId] = useState([])
    const { state } = useGlobalContext();
    
  return (
    debits?.length > 0 ? (
        debits?.map((debit, index) => (
          <div className='border-b-[1px] border-b-primaryGrey last:border-b-transparent flex flex-col '>
            <div key={index} className='flex flex-row  py-5 gap-10 mx-5'>
              <div className='w-[11%] self-center'>
                <p className=' font-MessinaSansSemiBold self-center'>
                  {debit.start_date}
                </p>
              </div>
              <div className='w-[11%] self-center  font-MessinaSansSemiBold items-center'>
                {' '}
                <p >{debit.end_date ? debit.end_date : 'No tiene'} </p>
              </div>
              <div className={`w-[30%] self-center`}>
                {' '}
                <p>{debit?.membership?.product.name}</p>
              </div>
              <div className={`w-[30%] self-center`}>
                {debit.discount && (
                  <span>
                    {debit.discount?.name}{' '}
                    <Tag bordered={false} color='green' className='ml-2'>
                      {parseFloat(debit?.discount?.amount).toFixed(0)}%
                    </Tag>
                  </span>
                )}
              </div>
              {/* {debits.some((p)=>p.debit_splits.some((s)=>s.credit_card)) &&(
            <div className='w-[10%]'>

            {debit.debit_splits.some((s)=>s.credit_card) &&
            (
              <>
              <p>{debit.debit_splits.find((s)=>s.credit_card)?.credit_card?.card_brand}</p>
              <p>
              {' '}
              {formatExtraInfo(debit.debit_splits.find((s)=>s.credit_card)?.credit_card?.extra_info).first_six_digits}****{' '}
              {debit.debit_splits.find((s)=>s.credit_card)?.credit_card?.last_four}
            </p>
              </>
            )}
               </div>
            )} */}
              <Tooltip
                placement='topLeft'
                title={`Cargado por ${debit.user_name} el ${dayjs(
                  debit.created_at
                ).format('DD/MM/YYYY HH:mm A')} en Sede ${
                  debit.location_name
                }`}
              >
                <div
                  className={`w-[12%] self-center  ${
                    dayjs().month() ==
                      dayjs(debit.end_date, 'YYYY-MM-DD').month() &&
                    debit.active
                      ? 'bg-primaryOrange'
                      : debit.active
                      ? 'bg-lightGreen'
                      : dayjs().isBefore(
                          dayjs(debit.start_date, 'YYYY-MM-DD')
                        ) && !debit.end_date
                      ? 'bg-primaryYellow'
                      : 'bg-primaryRed'
                  } rounded-full flex items-center justify-center `}
                >
                  <p
                    className={`text-center self-center ${
                      dayjs().month() ==
                        dayjs(debit.end_date, 'YYYY-MM-DD').month() ||
                      !debit.active
                        ? ' text-black'
                        : 'text-darkGreen'
                    } py-1 font-MessinaSansSemiBold uppercase`}
                  >
                    {dayjs().month() ==
                      dayjs(debit.end_date, 'YYYY-MM-DD').month() &&
                    debit.active
                      ? t('deactivated', 'Desactivado')
                      : debit.active && debit.end_date
                      ? t('active_withend_debit', 'Activo con Fecha de Fin')
                      : debit.active
                      ? t('active', 'Activo')
                      : dayjs().isBefore(
                          dayjs(debit.start_date, 'YYYY-MM-DD')
                        ) && !debit.end_date
                      ? t('new_debit', 'ALTA')
                      : t('inactive', 'Inactivo')}
                  </p>
                </div>
              </Tooltip>
              <div className='pl-10  flex flex-row gap-3 self-center'>
                <Tooltip title={t('edit_debit', 'Editar debito')}>
                  <Dropdown
                    placement='bottomRight'
                    menu={{
                      items: editProduct(debit),
                      onClick: async ({ key }) => {
                        if(key == 'edit_discount' || key == 'edit_product'){
                   return   handleOpenDebit({...debit,edit:key})
                        }
                        showPromiseConfirm(key, debit);
                      },
                    }}
                    trigger={['click']}
                    className=''
                  >
                    <div className='rounded-full  border-primaryGray border-[1px] p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200 '>
                      <MdOutlineModeEditOutline size={15} color='#ccc' />
                    </div>
                  </Dropdown>
                </Tooltip>
                <Tooltip title={t('show_more', 'Mostrar más')}>
                <div 
                 onClick={()=>{
                    if(showSplitId.includes(debit.id)){
                      setshowSplitId(showSplitId.filter((id)=>id!=debit.id))
                 } else {
                  setshowSplitId([...showSplitId,debit.id])
                 }
                }}
                className=' bg-primaryGrey  rounded-sm  text-white p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200 '>

                { showSplitId.includes(debit.id) ? <FaAngleUp 
                color='#FFF' size={15} />
                :  <FaAngleDown color='#FFF' size={15} />}
                    </div>
                </Tooltip>
              </div>
            </div>
            {debit.debit_splits.map((split, index) => (
              <div className={`flex-row  mb-4  justify-around border-t-[1px] border-t-primaryGrey pt-5 mx-12  ${showSplitId.some((l)=>l == debit.id) ? 'flex' :'hidden' }`}>
                <div className='w-[25%]'>
                  {index == 0 && (
                    <p className=' font-MessinaSansSemiBold mb-4 text-primaryGrey uppercase'>
                      Metodo de pago
                    </p>
                  )}{' '}
                  <p className=''>{split.payment_method_name}</p>
                </div>
                <div className=' w-[50%] '>
                  {index == 0 && (
                    <p className=' text-center font-MessinaSansSemiBold text-primaryGrey uppercase  mb-4'>
                      Porcentaje
                    </p>
                  )}{' '}
                  <p className='text-center'>{split.price_percentage}%</p>
                </div>
                {split?.credit_card ? (
                  <div className=' w-[25%]'>
                    <p className='font-MessinaSansSemiBold text-primaryGrey uppercase mb-2'>
                      Tarjeta
                    </p>

                    <p>
                      {' '}
                      { ! formatExtraInfo(split?.credit_card?.extra_info) ?'ID: '+split?.credit_card?.id :
                      
                        (formatExtraInfo(split?.credit_card?.extra_info)
                          ?.first_six_digits || split.credit_card.card_brand) + ` **** ${split?.credit_card?.last_four}`
                      }
                    
                    </p>
                  </div>
                ) : <div className='w-[25%]'></div>}
                <Dropdown
                  placement='bottomRight'
                  menu={{
                    items: editSplit(debit),
                    onClick: async ({ key }) => {
                      if(split.payment_method_id !== 52){
                        openNotification(
                          'error',
                          `No puedes modificar un debito con metodo de pago distinto a Integración MP`,
                          'Error'
                        );
                        return;
                      }
                      if (split.location_id !== getLocationId()) {
                        let findDebitLocation = getLocationById(
                          state.locations,
                          split.location_id
                        );

                        openNotification(
                          'error',
                          `No puedes modificar un debito de otra sede${
                            findDebitLocation &&
                            '. Debes hacerlo desde la sede ' +
                              findDebitLocation.name
                          }`,
                          'Error'
                        );
                        return;
                      }
                      if (key == 'generate_payment_link') {
                        handleLink(split.id,debit.membership.product.id, (debit.membership.product.price * (debit.discount?.id
                            ? 1 - parseFloat(debit.discount.amount) / 100
                            : 1) * split.price_percentage / 100 ))
                      } else {
                        setopenNotification1(split);
                      }
                 
                    },
                  }}
                  trigger={['click']}
                  className='self-center ml-auto'
                >
                  <div className=' bg-primaryGrey  rounded-sm  text-white p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200 '>
                    {/* <FaAngleDown color='#FFF' size={15} />{' '} */}
                    <BsThreeDots size={15} color='#FFF' />

                  </div>
                </Dropdown>
              </div>
            ))}
          </div>
        ))
      ) : (
        <div className='mx-5 self-center  pb-6 pt-5'>
          <p>
            {t('no_debits', 'Este socio no posee ningun debito automatico')}
          </p>
        </div>
      )
  )
}
