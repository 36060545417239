import React, { useState, useMemo, useRef } from 'react';
import CardRounded from './CardRounded';
import AddButton from './helpers/AddButton';
import { Divider, Progress, Space } from 'antd';
import { ConfigProvider, DatePicker, notification, message ,Checkbox, Tooltip, Select } from 'antd';
import es_ES from 'antd/locale/es_ES';
import { BsSortDown, BsSortUp } from 'react-icons/bs';
import { BiTrash } from 'react-icons/bi';
import { removeAlert, updateAlert, useGetAlerts } from '../api/crm';
import dayjs from 'dayjs';
import { BsChevronDown } from 'react-icons/bs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../context/AuthProvider';
import { AiOutlineUser } from 'react-icons/ai';
import { t } from 'i18next';
import utc from 'dayjs/plugin/utc';
import { getLocationName } from '../services/utils/getLocation';
import { CiShop } from "react-icons/ci";

// import { set } from 'date-fns';
dayjs.extend(utc);

const { RangePicker } = DatePicker;
const today = dayjs();
const first_day = dayjs().weekday(1);

export default function ToDoList({
  setModalMemberOpen,
  setOpenCRM,
  customer_id,
  styles,
}) {
  const queryClient = useQueryClient();

  const { user } = useAuth();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const [date_selected, setDate_selected] = useState([first_day, today]);
  const {
    isLoading,
    data: alerts,
    isSuccess,
  } = useGetAlerts(
    date_selected[0].format('YYYY-MM-DD'),
    date_selected[1].format('YYYY-MM-DD')
  );

  const datepickerRef = useRef(null);
  const filterUserRef = useRef(null);
const searchRef = useRef(null)
  const [showUndone, setshowUndone] = useState(false);
  const [showMine, setshowMine] = useState(false);
  const [sort_asc, setsort_asc] = useState(true);
  const [location_alerts, setlocation_alerts] = useState(false)
  const [messageApi, messageContext] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();
  const [selectedUserId, setselectedUserId] = useState(null);
  const [filterCrm, setfilterCrm] = useState(false)
  const [valueSearch, setvalueSearch] = useState('')
  const [filterBySearch, setfilterBySearch] = useState(false)
  const [prevSearchValue, setprevSearchValue] = useState('')
const [alertsOptions, setalertsOptions] = useState([]);
  const processing = () => {
    messageApi.open({
      type: 'loading',
      content: 'Procesando',
      duration: 0,
      // style: { backgroundColor: '#222222', color: 'white' },
    });
  };
React.useEffect(() => {
  if(alerts && alerts.length > 0){
    setalertsOptions(Object.values(alerts.filter((a)=> showUndone ? !a.performed_at : true)
    .filter((a)=> showMine ? a.user_id == user.user_id : true)
    .filter((a)=> selectedUserId ? a.user_id == selectedUserId : true)
      .reduce((acc, item) => {
      let key = item.user_first_name + ' ' + item.user_last_name;
      // Si la clave no existe en el acumulador, inicializarla con el formato deseado
      if (!acc[key]) {
        acc[key] = {
          label: <span>{item.user_first_name + ' ' + item.user_last_name}</span>,
          title: item.user_first_name + ' ' + item.user_last_name,
          options: []
        };
      }
      // Agregar la opción al array de opciones correspondiente
      acc[key].options.push({
        value: item.id,
        label: (item.customer_type == 'Prospect'
        ? item.prospect?.user?.first_name +
          ' ' +
          item.prospect?.user?.last_name
        : item.member?.user?.first_name +
          ' ' +
          item.member?.user?.last_name )+ ' - ' +    item.message
      });
      return acc;
    }, {})));
  }
 
}, [alerts,showUndone,showMine,selectedUserId])


  const rangePresets = [
    {
      label: 'Ultimos 7 Dias',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Ultimos 15 Dias',
      value: [dayjs().add(-15, 'd'), dayjs()],
    },
    {
      label: 'Ultimos 30 Dias',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
  ];
  const useUpdateAlert = useMutation({
    mutationFn: (alert) =>
      updateAlert(alert.id, {
        performed_at: alert.performed_at,
      }),
    onSuccess: () => {
      // openNotification('success');

      queryClient.invalidateQueries({
        queryKey: [
          'alerts',
          date_selected[0].format('YYYY-MM-DD'),
          date_selected[1].format('YYYY-MM-DD'),
        ],
      });
      messageApi.destroy();
    },
    onError: (error) => {
      messageApi.destroy();

      // openNotification('error');
    },
  });
  const getPaginatedTodoList = useMemo(() => {
    if (isLoading)
      return {
        data: [],
        totalPages: 0,
      };
    const { current, pageSize } = pagination;
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const sortedList = alerts.sort((a, b) => {
      const dateA = dayjs(a.sent_at);
      const dateB = dayjs(b.sent_at);
      if (dateA.isBefore(dateB)) {
        if (!sort_asc) return -1;
        return 1;
      }
      if (dateA.isAfter(dateB)) {
        if (!sort_asc) return 1;

        return -1;
      }
      return 0;
    });
    
    const filteredList = sortedList.filter((item) => {
      if (selectedUserId && item.user_id !== selectedUserId) return false;
      if (showUndone && item.performed_at) return false;
      if (showMine && item.user_id !== user.user_id) return false;
      if (customer_id && item.customer_id !== customer_id) return false;
      if(location_alerts && item.user_first_name) return false;
      if(filterBySearch && item.message.toLowerCase().indexOf(valueSearch.toLowerCase()) === -1) return false;
     if(filterCrm && item.id !== filterCrm) return false;
      return true;
    });


    const paginatedList = filteredList.slice(startIndex, endIndex);
  
  
    return {
      data: paginatedList,
      totalPages: Math.ceil(filteredList.length / pageSize),
      status: `${filteredList.filter((item) => item.performed_at).length}/${ filteredList.length }`,
      progress: (filteredList.filter((item) => item.performed_at).length / filteredList.length) * 100,
    };
  }, [
    isLoading,
    pagination,
    sort_asc,
    alerts,
    selectedUserId,
    showUndone,
    showMine,
    customer_id,
    filterCrm,
    filterBySearch,
    location_alerts
  ]);
  const useRemoveAlert = useMutation({
    mutationFn: (id) => removeAlert(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'alerts',
          date_selected[0].format('YYYY-MM-DD'),
          date_selected[1].format('YYYY-MM-DD'),
        ],
      });
      messageApi.destroy();
    },
    onError: (error) => {
      console.log();
      messageApi.destroy();
    },
  });

  return (
    <CardRounded styles={`max-h-[55rem]  pt-1  flex-grow px-5  ${styles} `}>
      <div className=' top-0  bg-primaryDark pt-6 relative'>
        <div className='flex flex-row justify-between items-center gap-5 column-mobile'>
          <h2 className='text-white font-bold text-2xl font-MessinaSansSemiBold'>
            {t('alerts.title', 'Alertas')}
          </h2>
          <ConfigProvider locale={es_ES}>
            <RangePicker
              ref={datepickerRef}
              presets={rangePresets}
              onChange={(dates, dateString) => {
                datepickerRef.current.blur();
                // setdateSelected(date);
                setDate_selected([dayjs(dateString[0]), dayjs(dateString[1])]);
              }}
              allowClear={false}
              defaultPickerValue={date_selected}
              defaultValue={date_selected}
              format={'YYYY-MM-DD'}
              showToday
              style={{
                width:240
              }}
              className='hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey bg-primaryGrey outline-none border-none py-1 '
            />
          </ConfigProvider>
       <Select
       ref={searchRef}
          showSearch
          optionFilterProp="children"
        options={
          alertsOptions
        }
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <span>Buscar alertas con <span 
            onClick={()=>{
              setfilterBySearch(true)
              searchRef.current.blur()
              setprevSearchValue(valueSearch)
            }}
            className=' underline text-darkGreen cursor-pointer'>{
            valueSearch
              }</span></span>
          </Space>
          </div>
        )}
        
        allowClear
        onClear={() => {
          setfilterBySearch(false)
          // setvalueSearch('')
          setfilterCrm(null)

        }}
        style={{
          backgroundColor: '#444444',
          outline: 'none',
          borderColor: 'transparent',
          flexGrow: 1,
          borderRadius: 6,
          color: '#fff',
          width:180
        }}
        bordered={false}
        onSearch={(value) => setvalueSearch(value)}
      onSelect={(newValue) => {
        searchRef.current.blur();
        if(newValue == filterCrm){
          setfilterCrm(null)

        }else{
        setfilterCrm(newValue)
        }
     
      }}
        filterOption={(input, option) =>
          (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toString().toLowerCase().localeCompare((optionB?.label ?? '').toString().toLowerCase())
        }
        placeholder="Buscar..."
      /> 
          <Select
            ref={filterUserRef}
            loading={isLoading}
            style={{
              backgroundColor: '#444444',
              outline: 'none',
              borderColor: 'transparent',
              flexGrow: 1,
              borderRadius: 6,
              color: '#fff',
            }}
            allowClear
            suffixIcon={<BsChevronDown color='#ADFF19' />}
            value={selectedUserId}
            optionLabelProp='title'
            options={
              isLoading
                ? []
                : Array.from(new Set(alerts))
                    .reduce((acc, alert) => {
                      if (
                        alert.user_id &&
                        !acc.some((item) => item.value === alert.user_id)
                      ) {
                        acc.push({
                          label:
                            alert.user_first_name + ' ' + alert.user_last_name,
                          value: alert.user_id,
                        });
                      }
                      return acc;
                    }, [])
                    .map((item) => ({
                      label: item.label,
                      value: item.value,
                      title: item.label,
                    }))
            }
            bordered={false}
            placeholder={
              <div className='flex flex-row items-center gap-2'>
                <AiOutlineUser />
                {t('users.user', 'Usuario')}
              </div>
            }
            onSelect={(newValue) => {
              filterUserRef.current.blur();
              if (newValue == selectedUserId) {
                setselectedUserId(null);
              }
            }}
            onChange={(value) => {
              filterUserRef.current.blur();
              if (!value) return setselectedUserId(null);
              setselectedUserId(value);
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
          <AddButton
            title={t('alerts.new_alert', 'Nueva Alerta')}
            onClick={() =>
              setOpenCRM(
                true,
                6,
                date_selected[0].format('YYYY-MM-DD'),
                date_selected[1].format('YYYY-MM-DD')
              )
            }
            greenColor
            className='add-btn-mobile'
          />
        </div>
        <div className='flex flex-row items-center justify-between mt-8 relative'>
          <div className='flex flex-row gap-4 items-center'>
            <p>{t('alerts.show', 'Mostrar')}</p>
            <button
              onClick={() => setshowUndone(!showUndone)}
              className={`rounded-md ${
                showUndone ? 'bg-lightGreen text-black ' : 'bg-primaryGrey'
              } py-1 px-3 cursor-pointer duration-300 ease-in-out`}
            >
              {t('alerts.incomplete', 'Incompletas')}
            </button>
            <button
              onClick={() => setshowMine(!showMine)}
              className={`rounded-md ${
                showMine ? 'bg-lightGreen text-black' : 'bg-primaryGrey'
              }  py-1 px-3 cursor-pointer duration-300 ease-in-out`}
            >
              {t('alerts.my_alerts', 'Mis alertas')}
            </button>
            {alerts?.some((a)=>!a.user_first_name)&& (
               <button
               onClick={() => setlocation_alerts(!location_alerts)}
               className={`rounded-md ${
                location_alerts ? 'bg-lightGreen text-black' : 'bg-primaryGrey'
               }  py-1 px-3 cursor-pointer duration-300 ease-in-out`}
             >
               {t('alerts.alerts_locations', 'Alertas de la sede')}
             </button>
            )}
          { filterBySearch &&  <button
              onClick={() => {
                setfilterBySearch(false)
                setprevSearchValue('')
              }}
              className={`rounded-md ${
                'bg-lightGreen text-black'
              }  py-1 px-3 cursor-pointer duration-300 ease-in-out`}
            >
              {t('alerts.hide_alertsWith', 'Ocultar Alertas con')} <span className='underline'>{prevSearchValue}</span>
            </button>}
          </div>
          <button
            onClick={() => setsort_asc(!sort_asc)}
            className='rounded-md bg-primaryGrey py-1 px-3 cursor-pointer flex flex-row gap-2 items-center duration-300 ease-in-out desktop'
          >
            {sort_asc ? <BsSortDown /> : <BsSortUp />}
            <span>{t('alerts.show_recent', 'Ver recientes')}</span>
          </button>
        </div>
      </div>
      <div className='flex flex-row items-center gap-4'>
        {!isLoading && <span>{getPaginatedTodoList.status}</span>}
        <Progress
          percent={isLoading ? 0 : getPaginatedTodoList.progress}
          strokeColor='#CEF5CE'
          trailColor='#444444'
          showInfo={false}
          className='my-8'
        />
      </div>

      <div className='min-h-[31rem]'>
        {getPaginatedTodoList.data.map((item, i) => (
          <div
            key={i}
            className={`flex flex-row mb-2 last:border-none items-center border-b-[1px] border-b-primaryGrey py-3 column-mobile ${
              item.performed_at && 'opacity-40 line-through '
            }`}
          >
            {!item.user_first_name && (
              <Tooltip title={t('alerts.location_name', 'Alerta de la sede')}>
              <CiShop size={40} color='#CEF5CE' className='mr-3' />
              </Tooltip>

            )}
            <div className='bg-primaryGrey px-2 py-2 rounded-md w-28 '>
              <Checkbox
                value={item.id}
                checked={item.performed_at == null ? false : true}
                onClick={async (e) => {
                  e.stopPropagation();
                  processing();
                  await useUpdateAlert.mutate({
                    id: e.target.value,
                    performed_at: item.performed_at
                      ? null
                      : dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
                  });
                }}
              >
                <p className='text-white text-center '>
                  {dayjs(item.sent_at).format('DD/M/YYYY')}
                </p>
              </Checkbox>
            </div>
            <div className='flex flex-row items-center justify-between mx-3 w-full'>
              <div className='gap-4 flex flex-row items-center w-[90%]'>
                <button
                  className='w-[30%]'
                  onClick={() =>
                    setModalMemberOpen({
                      id: item.customer_id,
                      isProspect: item.customer_type == 'Member' ? false : true,
                      customer_type:item.customer_type
                    })
                  }
                >
                  <span className=' text-lightGreen font-MessinaSansSemiBold '>
                    {
                    item.customer_type == 'Lead'? item.user_first_name + ' '+ item.user_last_name :
                    
                    item.customer_type == 'Prospect'
                      ? item.prospect.user.first_name +
                        ' ' +
                        item.prospect.user.last_name
                      : item?.member?.user?.first_name +
                        ' ' +
                        item?.member?.user?.last_name}
                  </span>
                </button>
                <span className='w-[80%] first-letter:capitalize'>{item.message}</span>
              </div>
              <div className='flex items-center gap-5 w-[30%]'>
                <span className='opacity-40 w-[90%] text-center '>
                  <Tooltip
                    title={t('alerts.user_assinged', 'Usuario asignado')}
                  >
                    <button>
                      {`
                      ${!item.user_first_name ? 'BIGG '+ getLocationName(): `${item.user_first_name} ${item.user_last_name}`}
                      `}
                    </button>
                  </Tooltip>
                </span>
                <div
                  onClick={async () => {
                    processing();
                    await useRemoveAlert.mutateAsync(item.id);
                  }}
                  className='rounded-full  border-primaryRed border-[1px] p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200'
                >
                  <Tooltip
                    title={`
                    ${t('alerts.delete_alert', 'Eliminar alerta')}
                `}
                  >
                    <BiTrash size={18} color='#ED4C5C' />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='sticky bottom-0 bg-primaryDark h-22 w-full flex items-center justify-center gap-3 p-5 border-t-[1px] border-t-primaryGrey'>
        {Array.from({ length: getPaginatedTodoList.totalPages }).map((_, i) => {
          const page = i + 1;
          return (
            <button
              key={i}
              onClick={() =>
                setPagination({
                  ...pagination,
                  current: page,
                })
              }
              className={` rounded-md ${
                pagination.current == page ? 'bg-lightGreen' : 'bg-primaryGrey'
              } h-10 w-10 flex items-center justify-center ease-in-out duration-300`}
            >
              <span
                className={`text-lg font-MessinaSansSemiBold ${
                  pagination.current == page ? 'text-black' : 'text-white'
                }`}
              >
                {page}
              </span>
            </button>
          );
        })}
      </div>
      {contextHolder}
      {messageContext}
    </CardRounded>
  );
}
