import React, { useState, useEffect } from 'react';
import MPCardHolder from '../components/Desktop/MPCardHolder';
import { getPayByLinkToken, patchPayByLinkToken, useGetPaymentPlatforms } from '../api/mp';
import { t } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetProduct } from '../api/products';
import { useGetDocumentTypes, useGetUser } from '../api/users';
import SpinIndicador from '../components/helpers/SpinIndicador';

import DoneIcon from '../assets/icons/Done';
import ErrorIcon from '../assets/icons/Error';
import * as Sentry from '@sentry/react';
import { formatPrice } from '../services/utils/formatPrice';
import {
  updateDebitSplit,
  useGetDebits,
  useGetDebitSplits,
} from '../api/debits';
import { createPayment } from '../api/payments';
import { useGetSale } from '../api/sales';
import { useMutation } from '@tanstack/react-query';
export default function ClientMpUpload() {
  const [searchParams] = useSearchParams();
  const token_uuid = searchParams.get('uuid');
  const product_id = searchParams.get('p_id');
  const product_amount = searchParams.get('p_a');
  const owner_user_id = searchParams.get('owner_user_id');
  const location_id = searchParams.get('location_id');
  const card_upload = searchParams.get('card_upload');
  const currency = searchParams.get('curr');
  const locale = searchParams.get('loc');
  const debit_split = searchParams.get('split_id');
  const point_of_sale_id = searchParams.get('p_of_s');
  const customer_type = searchParams.get('c_t');
  const customer_id = searchParams.get('c_id');
  const [singe_access_token, setsinge_access_token] = useState(null);
  const [payment_data, setpayment_data] = useState(null);
  useEffect(() => {
    if (token_uuid) {
      PayByLinkToken(token_uuid);
    }
  }, [token_uuid]);
  const PayByLinkToken = async (token_uuid) => {
    try {
      const data = await getPayByLinkToken(token_uuid, location_id);
      if (data.token) {
        setsinge_access_token(data.token);
        setpayment_data(data);
      } else {
        Sentry.captureException(new Error('Error al obtener el token'), {
          extra: {
            location_id,
            token_uuid,
            link: 'getPayByLinkToken',
            data: data,
          },
        });
        setoperation_type('error');
      }
    } catch (error) {
        if(JSON.parse(error.message).error == 'token_expired'){
          return setoperation_type('token_expired');
        }
      Sentry.captureException(new Error(error.message), {
        extra: {
          location_id,
          token_uuid,
        },
      });
      setoperation_type('error');
    }
  };
  const { data: user, isLoading: isLoadingUser } = useGetUser(
    owner_user_id,
    singe_access_token,
    {
      enabled: !!singe_access_token,
      location_id,
    }
  );
  const { data: product, isLoading: isLoadingProduct } = useGetProduct(
    product_id,
    singe_access_token,
    {
      enabled: !!singe_access_token && !!product_id,
    }
  );
  const { data: paymentPlatform, isLoading: loadingPlatforms } =
    useGetPaymentPlatforms(location_id, singe_access_token, {
      enabled: !!singe_access_token,
    });
  const { data: document_types, isLoading: isLoadingDocument_types } =
    useGetDocumentTypes(singe_access_token, {
      enabled: !!singe_access_token,
      location_id,
    });

  const { data: data_debit_split, isLoading: isloadingSplit } =
    useGetDebitSplits(debit_split, location_id, singe_access_token, {
      enabled: !!singe_access_token && !!debit_split,
    });

  const { data: data_debit, isLoading: isloadingDebit } = useGetDebits(
    data_debit_split?.debit_id,
    location_id,
    singe_access_token,
    {
      enabled: !!singe_access_token && !!debit_split && !!data_debit_split,
    }
  );
  const { data: data_sale, isLoading: isloadingSale} = useGetSale(
    payment_data?.sale_id,
    location_id,
    singe_access_token,
    {
      enabled: !!singe_access_token && !!point_of_sale_id && !!payment_data,
    }
  );
  const [operation_type, setoperation_type] = useState(''); //success or error
  useEffect(() => {
    if(operation_type == 'success'){
      useUpdateLink.mutate({id: payment_data.id, data: {expiration_minutes: 1}});
    }
  }, [operation_type])
  const useUpdateLink = useMutation({
    mutationFn: (link) => patchPayByLinkToken(link.id, link.data, location_id),
    onSuccess: () => {
    },
  });
  const params = {
    product_id,
    product_amount,
    owner_user_id,
    location_id,
  };
  if (
    (!card_upload && !product_id && !product_amount) ||
    !owner_user_id ||
    !location_id
  ) {
    return <div>Missing parameters</div>;
  }
useEffect(() => {
  if(data_sale && data_sale.id){
  let totalPayments = data_sale.payments.reduce((acc, payment) => {
    return acc + parseFloat(payment.amount);
  }, 0);
  if (totalPayments  >= data_sale.amount ||(product_amount > (data_sale.amount - totalPayments))) { 
    setoperation_type('payment_done');
  }
}
}, [data_sale])

if (operation_type == 'token_expired') {
  return (
    <div className='w-full flex flex-col  items-center justify-center h-screen pb-24'>
      <div className='flex flex-col gap-5 justify-center items-center'>
        <ErrorIcon large />
        <p className=' text-white text-2xl text-center px-8'>
          {' '}
         El link ya ha expirado. Por favor,{' '}
          <span>
            <a>
              solicitale a tu vendedor que te envíe un nuevo link
              </a>.
          </span>{' '}
          Si el error persiste, por favor{' '}
          <span>
            <a>contactá a soporte</a>.
          </span>{' '}
        </p>
      </div>
    </div>
  );
}
  if (
    isLoadingUser ||
    !singe_access_token ||
    loadingPlatforms ||
    (debit_split && !data_debit)
    || (payment_data.sale_id && !data_sale)
  ) {
    return (
      <div className='w-full  mt-10 flex flex-col  items-center h-screen'>
        <SpinIndicador />
        <p className='mt-1'>Cargando...</p>
      </div>
    );
  }
  if (operation_type == 'success') {
    return (
      <div className='w-full flex flex-col  items-center justify-center h-screen pb-24'>
        <div className='flex flex-col gap-5 justify-center items-center'>
          <DoneIcon large />
          <p className=' text-white text-2xl text-center px-8'>
            {' '}
            {user.first_name}, ya procesamos tu tarjeta en el sistema. Esto va a
            facilitar tus pagos y tu experiencia en BIGG. Muchas gracias!
          </p>
        </div>
      </div>
    );
  }
  if (operation_type == 'error') {
    return (
      <div className='w-full flex flex-col  items-center justify-center h-screen pb-24'>
        <div className='flex flex-col gap-5 justify-center items-center'>
          <ErrorIcon large />
          <p className=' text-white text-2xl text-center px-8'>
            {' '}
            {user.first_name}, tuvimos un error al procesar tu tarjeta. Por
            favor,{' '}
            <span>
              <a>volve a intentarlo</a>.
            </span>{' '}
            Si el error persiste, por favor{' '}
            <span>
              <a>contactá a soporte</a>.
            </span>{' '}
          </p>
        </div>
      </div>
    );
  }
  if (operation_type == 'payment_done') {
    return (
      <div className='w-full flex flex-col  items-center justify-center h-screen pb-24'>
        <div className='flex flex-col gap-5 justify-center items-center'>
          <DoneIcon large />
          <p className=' text-white text-2xl text-center px-8'>
            {' '}
            {user.first_name}, ya procesamos tu pago en el sistema. Esto va a
            facilitar tus pagos y tu experiencia en BIGG. Muchas gracias!
          </p>
        </div>
      </div>
    );
  }

  const patch_split = async (id, data, card) => {
    let split = {
      id: id,
      data: {
        ...data,
        credit_card_id: card.id,
      },
    };
    try {
      let response = await updateDebitSplit(split.id, split.data);
      setoperation_type('success');
    } catch (error) {
      Sentry.captureException(new Error(error.message), {
        extra: {
          location_id,
          token_uuid,
          link: 'updateDebitSplit',
          data: split,
        },
      });
      setoperation_type('error');
    }
  };

  const create_payment = async (card) => {
    let data = {
        payment: {
          payment_method_id: 52, // integration
          amount: product_amount,
          currency: currency,
          sale_id: payment_data.sale_id,
          location_id:  parseInt(location_id),
          customer_id: parseInt(customer_id),
          customer_type: customer_type,
          point_of_sale_id: parseInt(point_of_sale_id),
          user_id: payment_data.user_id,
          credit_card_id: card.id,
          device_id: window.MP_DEVICE_SESSION_ID,
        },
    };
    
    try {
      let response = await createPayment(data);
      console.log('response', response);
      setoperation_type('success');
    } catch (error) {
      Sentry.captureException(new Error(error.message), {
        extra: {
          location_id,
          token_uuid,
          link: 'createPayment',
          data: data,
        },
      });
      setoperation_type('error');
    }
  };
  return (
    <>
      <div className='mx-5 my-4'>
        <h1 className='text-2xl  font-MessinaSansSemiBold  mb-3'>
          {t('sales.add_card_paymentMethod', 'Agregar nuevo método de pago')}
        </h1>
        <p className='mb-4'>Hola {user.first_name} {user.last_name}!
 A continuación,  completá los datos de tu método de pago.

        </p>
        {!card_upload && (
          <div className=' bg-primaryGrey p-4 rounded-md mb-5'>
            <h3 className='mb-2'>Tu producto</h3>
            <p className='text-lightGreen text-xl font-MessinaSansSemiBold'>
              {product?.name}
            </p>
           {debit_split && <span className='text-sm'>
              Subscripcion Mensual | Inicia {data_debit?.start_date}
            </span>}
            <div className='flex flex-row gap-2 items-center mt-3'>
              <p className='text-lightGreen font-MessinaSansSemiBold text-lg'>
                {currency
                  ? formatPrice(product_amount, currency, locale)
                  : `$${product_amount}`}
              </p>
              <span className='text-sm text-lightGreen'></span>
            </div>
          </div>
        )}
        <MPCardHolder
          background={'#111111'}
          totalAmount={1}
          client_link={true}
          paymentPlatform={paymentPlatform}
          document_types={document_types}
          email={user.email}
          modalSale={true}
          selectedProducts={[{ id: 1, quantity: 1 }]}
          setClose={async (type, card) => {
            if (data_debit_split) {
              await patch_split(data_debit_split.id, data_debit_split, card);
            } else if (payment_data.sale_id) {
              await create_payment(card);
            } else {
              setoperation_type(type);
            }
          }}
          user_id={user.id}
        />
      </div>
    </>
  );
}
